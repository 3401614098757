import React, { useEffect } from "react";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { FC } from 'react';
import { useRouter } from "../../routes/hooks";

export const PrivacyPage: FC<{ src: string }> = ({ src }: { src: string }) => {
    const router = useRouter();
    const backTerms = () => {
        if (localStorage.getItem("access_token")) {
            router.push("/dashboard");
        } else {
            router.push("/");
        }
        // router.back();
    }
    return (
        <div className="w-full h-screen relative">
            <button className="fixed top-6 left-6 rounded-full border-[1px] bg-[#000] h-[40px] w-[40px]" onClick={() => backTerms()}><ArrowBackRoundedIcon /></button>
            <iframe src={src} style={{ width: '100%', height: '100vh', background: '#fff' }} title="IFrame Component"></iframe>
        </div>
    )
}