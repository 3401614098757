import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { UserData } from "../pages/interface";
import { firestore } from "../components/Firebase/firebase";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import { useEffect } from "react";

export function formatNumber(number: number): string {
  if (number >= 1000000) {
    // Format numbers in millions
    const millions = number / 1000000;
    return `${millions.toFixed(millions >= 10 ? 0 : 1)}M`;
  } else if (number >= 1000) {
    // Format numbers in thousands
    const thousands = number / 1000;
    return `${thousands.toFixed(thousands >= 10 ? 0 : 1)}K`;
  } else {
    // For numbers less than a thousand, just return the number
    return number.toFixed(0);
  }
}


export const getUserDataByToken = async (
  token: string
): Promise<UserData | null> => {
  try {
    // Reference to the 'users' collection
    const usersRef = collection(firestore, "users");

    // Create a query to filter by email
    const q = query(usersRef, where("accessToken", "==", token));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data() as UserData;
      userData.doc_id = querySnapshot.docs[0].id;
      return userData;
    } else {
      console.log("No user found with the given token.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data by token:", error);
    return null;
  }
};

export const updateUserDataByEmail = async (
  id: string,
  updatedData: Partial<any>
): Promise<{ success: boolean; message: string }> => {
  try {
    // Reference to the user document using the doc id as identifier
    const userDocRef = doc(firestore, "users", id);

    // Update the user data in Firestore
    await updateDoc(userDocRef, updatedData);

    return { success: true, message: "Update successful" };
  } catch (error) {
    console.error("Error updating user data:", error);
    return { success: false, message: "An error occurred while updating." };
  }
};

export const checkIfEmailExists = async (email: string) => {
  const auth = getAuth();

  try {
    // Fetch the sign-in methods for the email
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    // If the array is empty, the email is not registered
    if (signInMethods.length === 0) {
      console.log("Email not registered");
      return false; // Email does not exist
    } else {
      console.log("Email already exists");
      return true; // Email exists
    }
  } catch (error) {
    console.error("Error checking email existence:", error);
    return false; // In case of an error, assume the email doesn't exist
  }
};

export const getUserDataByMail = async (
  mail: string
): Promise<UserData | null> => {
  try {
    // Reference to the 'users' collection
    const usersRef = collection(firestore, "users");

    // Create a query to filter by email
    const q = query(usersRef, where("mail", "==", mail));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data() as UserData;
      userData.doc_id = querySnapshot.docs[0].id;
      return userData;
    } else {
      console.log("No user found with the given token.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data by token:", error);
    return null;
  }
};

export const getTopPerformingCampaignIndex = (campaigns: any, metric: any) => {
  let topIndex = 0;
  let maxMetricSum = 0;

  campaigns.forEach((campaign: any, index: any) => {
    const metricSum = campaign.data.reduce((sum: any, record: any) => sum + record[metric], 0);
    if (metricSum > maxMetricSum) {
      maxMetricSum = metricSum;
      topIndex = index;
    }
  });

  return topIndex;
}

export const calculateTotals = (campaigns: any) => {
  let maxCtr = 0;
  const totals = {
    impressions: 0,
    clicks: 0,
    conversions: 0,
    ctr: 0,
    cpa: 0,
    spend: 0,
  };

  campaigns.forEach((campaign: any) => {
    campaign.data.forEach((item: any) => {
      totals.impressions += item.impressions;
      totals.clicks += item.clicks;
      totals.conversions += item.conversions;
      totals.spend += item.cost;
      if (item.ctr > maxCtr) { // Update max CTR if current item's CTR is higher
        maxCtr = item.ctr;
      }
      totals.cpa += item.cpa;
    });
  });
  totals.spend = parseFloat(totals.spend.toFixed(2));
  totals.ctr = Number((maxCtr / 100).toFixed(2));
  totals.cpa = totals.cpa / campaigns.length;
  return totals;
}

export const sumFieldsByCampaign = (campaigns: any) => {
  const results: {
    [key: string]: {
      impressions: number;
      clicks: number;
      conversions: number;
      ctr: number;
      cpa: number;
    }
  } = {};

  campaigns.forEach((campaign: any) => {
    if (!results[campaign.campaign_name]) {
      results[campaign.campaign_name] = {
        impressions: 0,
        clicks: 0,
        conversions: 0,
        ctr: 0,
        cpa: 0
      };
    }

    campaign.data.forEach((item: any) => {
      results[campaign.campaign_name].impressions += item.impressions;
      results[campaign.campaign_name].clicks += item.clicks;
      results[campaign.campaign_name].conversions += item.conversions;
      results[campaign.campaign_name].ctr += item.ctr;
      results[campaign.campaign_name].cpa += item.cpa;
    });
  });

  const resultsArray = Object.keys(results).map(key => ({
    campaign_name: key,
    data: {
      impressions: results[key].impressions,
      clicks: results[key].clicks,
      conversions: results[key].conversions,
      ctr: results[key].ctr,
      cpa: results[key].cpa
    }
  }));

  return resultsArray;
}


export const processCampaignData = (campaignData: any) => {
  return campaignData.map((campaign: any) => {
    let channel;
    switch (campaign.campaign.advertising_channel_type) {
      case 2:
        channel = 'Search Ads';
        break;
      case 3:
        channel = 'Display Ads';
        break;
      case 4:
        channel = 'Shopping Ads';
        break;
      case 5:
        channel = 'Hotel Ads';
        break;
      case 6:
        channel = 'Video Ads';
        break;
      case 7:
        channel = 'Multi Channel';
        break;
      case 8:
        channel = 'Local Ads';
        break;
      case 9:
        channel = 'Smart Ads';
        break;
      case 10:
        channel = 'Discovery Ads';
        break;
      default:
        channel = 'Unknown';
    }

    return {
      strategyName: campaign.campaign.name,
      channel: channel,
      ctr: (campaign.metrics.clicks / campaign.metrics.impressions * 100).toFixed(2),
      conversionRate: (campaign.metrics.conversions / campaign.metrics.clicks * 100).toFixed(2),
      cac: (campaign.metrics.average_cpc / 1000000).toFixed(2), // cost is in micros
    };
  });
};


export const updateOrCreateTopCampaign = async (
  campaignData: any,
  userData: any
): Promise<{ success: boolean; message: string }> => {
  try {
    const campaignsRef = collection(firestore, "campaigns");
    const q = query(campaignsRef, where("mail", "==", userData.mail));
    const querySnapshot = await getDocs(q);

    const newCampaignData = {
      ...campaignData,
      mail: userData.mail,
      type: "googleads",
      industry: userData.experience.industry,
      companyName: userData.experience.companyName,
    };

    if (!querySnapshot.empty) {
      const campaignDocRef = doc(firestore, "campaigns", querySnapshot.docs[0].id);
      await updateDoc(campaignDocRef, newCampaignData);
      return { success: true, message: "Campaign updated successfully" };
    } else {
      await setDoc(doc(campaignsRef), newCampaignData);
      return { success: true, message: "Campaign created successfully" };
    }
  } catch (error) {
    console.error("Error updating or creating campaign:", error);
    return { success: false, message: "An error occurred while updating or creating the campaign." };
  }
};

export const getTopPerformingCampaign = async (): Promise<{ success: boolean; message: string; campaignData?: any }> => {
  try {
    const campaignsRef = collection(firestore, "campaigns");

    // Execute the query to get all campaign data
    const querySnapshot = await getDocs(campaignsRef);

    if (!querySnapshot.empty) {
      // Get all campaign data
      const allCampaignData = querySnapshot.docs.map(doc => doc.data());

      // Sort the campaign data based on impressions in descending order
      allCampaignData.sort((a, b) => b.ctr - a.ctr);

      // Return the campaign data with the highest impressions
      return {
        success: true,
        message: "Top performing campaign data retrieved successfully",
        campaignData: allCampaignData[0]
      };
    } else {
      return { success: false, message: "No campaign data found." };
    }
  } catch (error) {
    console.error("Error retrieving top performing campaign data:", error);
    return { success: false, message: "An error occurred while retrieving the campaign data." };
  }
};


export const calculateGrowthRate = (oldValue: any, newValue: any) => {
  return ((newValue - oldValue) / oldValue) * 100;
}
export const sumCampaigns = (data: any) => {
  let summedData = data.map((item: any) => {
    // Sum the data for each campaign
    let summedItemData = item.data.reduce((sum: any, current: any) => {
      return {
        id: current.id,  // Assuming the id is the same for all data points
        name: current.name,  // Assuming the name is the same for all data points
        impressions: sum.impressions + current.impressions,
        clicks: sum.clicks + current.clicks,
        conversions: sum.conversions + current.conversions,
        ctr: sum.ctr + current.ctr,
        cpa: sum.cpa + current.cpa,
        cost: sum.cost + current.cost
      };
    }, {
      id: 0,
      name: '',
      impressions: 0,
      clicks: 0,
      conversions: 0,
      ctr: 0,
      cpa: 0,
      cost: 0
    });

    return {
      campaign_name: item.campaign_name,
      data: summedItemData
    };
  });
  return summedData
}

export const sumAudienceGender = (data: any) => {
  data.sort((a: any, b: any) => a.ad_group_criterion.gender.type - b.ad_group_criterion.gender.type);

  // Group and sum the data by ad_group_criterion.gender.type
  let groupedData = data.reduce((acc: any, curr: any) => {
    let key = curr.ad_group_criterion.gender.type;
    if (!acc[key]) {
      acc[key] = {
        clicks: 0,
        cost_micros: 0,
        impressions: 0
      };
    }
    acc[key].clicks += curr.metrics.clicks;
    acc[key].cost_micros += curr.metrics.cost_micros;
    acc[key].impressions += curr.metrics.impressions;
    return acc;
  }, {});

  let totalClicks = 0;
  let totalCostMicros = 0;
  let totalImpressions = 0;
  for (let key in groupedData) {
    totalClicks += groupedData[key].clicks;
    totalCostMicros += groupedData[key].cost_micros;
    totalImpressions += groupedData[key].impressions;
  }

  // Calculate the percentage for each gender type
  for (let key in groupedData) {
    groupedData[key].clicks = (groupedData[key].clicks / totalClicks) * 100;
    groupedData[key].cost_micros = (groupedData[key].cost_micros / totalCostMicros) * 100;
    groupedData[key].impressions = (groupedData[key].impressions / totalImpressions) * 100;
  }


  return groupedData;
}

export const sumLocation = (data: any) => {
  let locationCounts = data.reduce((acc : any, curr : any) => {
    // Check if the current item has a location
    if (curr.campaign_criterion.location) {
        let location = curr.campaign_criterion.location.geo_target_constant;
        // If the location is already in the accumulator, increment its count
        if (acc[location]) {
            acc[location]++;
        } else {
            // Otherwise, add the location to the accumulator with a count of 1
            acc[location] = 1;
        }
    }
    return acc;
}, {});

  return locationCounts;
}