import React, { ReactElement, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { imageAssets, SERVER_URL } from "../../utils/constant";
import { useRouter } from "../../routes/hooks/index";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  LineChartData,
  LineChartComponent,
} from "../../components/LineChartComponent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { InfoAlert } from "../../components/InfoAlert";
// import { StrategyTable } from "./strategyTable";
// import Grid from "@mui/material/Grid";
// import { SettingsTable } from "./settingTable";
import { useNavigate } from "react-router-dom";
import UserContext from "../../utils/userContext";
import axios from "axios";
// import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import { getTopPerformingCampaignIndex } from '../../utils/helper';
import { CampaignImage } from "../component/campaignImage";
import toast from "react-hot-toast";

const taps: string[] = ["30d", "Images", "Videos", "Carousel"];
const tempImages: string[] = [
  imageAssets.temp1,
  imageAssets.temp2,
  imageAssets.temp3,
  imageAssets.temp4,
];
const industryImages: string[] = [
  imageAssets.indus1,
  imageAssets.indus2,
  imageAssets.indus3,
  imageAssets.indus4,
];


export const Dashboard = () => {
  const themeContext = useContext(ThemeContext);
  const { setPages, setloading } = useContext(UserContext);
  const [campaignIndex, setCampaignIndex] = useState<number>(0);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [topCampaign, setTopCampaign] = useState<any>({});
  const [topCreativeUrl, setTopCreativeUrl] = useState<string>();
  const router = useRouter();

  type CampaignDataEntry = {
    date: string;
    impressions: number;
    clicks: number;
    conversions: number;
    CPA: number;
    campaignName: string;
  };
  const handleGoPage = (url: string) => {
    router.push(`/${url}`);
  };
  const [selectTap, setSelectTap] = useState<number>(0);
  const navigate = useNavigate();
  const setGoal = () => {
    setPages(3);
    navigate("/");
  };

  const [perform, setPerform] = useState<CampaignDataEntry[]>([{
    date: '',
    campaignName: '',
    impressions: 0,
    clicks: 0,
    conversions: 0,
    CPA: 0,
  }]);
  const [testData, setTestData] = useState<LineChartData>({
    firstData: [],
    secondData: [],
    thirdData: [],
    fourthData: [],
    xLabel: []
  });
  const access_token = localStorage.getItem("access_token");
  const customerId = localStorage.getItem("customerId")?.replace(/-/g, '');
  const fetchCreativeData = async (topCampaigns: any) => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/campaigns/top-creative`, {
        refresh_token: access_token,
        customerId: customerId,
        campaignId: topCampaigns?.data[0]?.campaignId,
      });
      console.log(result.data);
      setTopCreativeUrl(result.data[0]?.asset?.image_asset?.full_size?.url);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Unexpected Error", error);
    }
  };

  const initialze = async () => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/dashboard`, {
        refresh_token: access_token,
        customerId: customerId,
        days: 7,
      });
      // fetch performance metrics datas
      setCampaignData(result.data);
      setPerform(result.data[campaignIndex]?.data);
      setChartData(result.data[campaignIndex]?.data);
      const topCampaignIndex = getTopPerformingCampaignIndex(result.data, 'impressions');

      setTopCampaign(result.data[topCampaignIndex]);
      await fetchCreativeData(result.data[topCampaignIndex]);
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  useEffect(() => {
    initialze();
  }, []);


  const getTotalValue = (key: keyof CampaignDataEntry): number => {
    return perform.reduce((total: any, data: any) => total + data[key], 0);
  };

  const getTopTotalValue = (key: keyof CampaignDataEntry): number => {
    return topCampaign?.data ? topCampaign?.data.reduce((total: any, data: any) => total + data[key], 0) : 0;
  };

  const formatNumber = (value: number): string => {
    return value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value.toFixed(2).toString();
  };

  const setChartData = (data: any) => {
    const firstData = data.map((item: any) => item.impressions);
    const secondData = data.map((item: any) => item.clicks);
    const thirdData = data.map((item: any) => item.conversions);
    const fourthData = data.map((item: any) => item.CPA);
    const xLabel = data.map((item: any) => item.date);
    setTestData({ firstData, secondData, thirdData, fourthData, xLabel });
  }

  const fetchYears = async () => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/dashboard/years`, {
        refresh_token: access_token,
        customerId: customerId,
        days: 0,
      });
      // fetch performance metrics datas
      setCampaignData(result.data);
      setPerform(result.data[campaignIndex]?.data);
      setChartData(result.data[campaignIndex]?.data);

      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  }

  const fetchMonth = async (days: number) => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/dashboard`, {
        refresh_token: access_token,
        customerId: customerId,
        days
      });
      // fetch performance metrics datas
      setCampaignData(result.data);
      setPerform(result.data[campaignIndex]?.data);
      setChartData(result.data[campaignIndex]?.data);
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  }

  const fetchTopYears = async () => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/dashboard/years`, {
        refresh_token: access_token,
        customerId: customerId,
        days: 0,
      });
      const topCampaignIndex = getTopPerformingCampaignIndex(result.data, 'impressions');
      setTopCampaign(result.data[topCampaignIndex]);
      await fetchCreativeData(result.data[topCampaignIndex]);
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  }

  const fetchTopMonth = async (days: number) => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/dashboard`, {
        refresh_token: access_token,
        customerId: customerId,
        days
      });
      const topCampaignIndex = getTopPerformingCampaignIndex(result.data, 'impressions');
      setTopCampaign(result.data[topCampaignIndex]);
      await fetchCreativeData(result.data[topCampaignIndex]);
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  }
  return (
    <div>
      {/** Title Part */}
      <div className="flex flex-col  md:flex-row items-start md:items-center my-[8px]">
        <h1
          className="w-[30%]"
          style={{ color: themeContext?.theme.titleColor }}
        >
          Dashboard
        </h1>
        {/* {localStorage.getItem("goalSetting") ? (
          <></>
        ) : (
          <>
            <h4
              className="md:w-[30%] mt-[16px] md:mt-0 md:block flex flex-row gap-[8px]"
              style={{ color: themeContext?.theme.titleColor }}
            >
              Global Score &nbsp;
              <label>
                <label style={{ color: "#6775F0" }}>85</label>/100
              </label>
            </h4>
            <div
              className="flex flex-row items-center md:justify-end h4 md:gap-x-[20px] gap-x-[8px] md:w-[40%] w-full"
              style={{ color: themeContext?.theme.titleColor }}
            >
              <label className="w-[35%]">
                Account Setup Progress{" "}
                <label style={{ color: "#6775F0" }}>3</label>
                /6
              </label>
              <ProgressBar step={2} />
              <button
                className="rounded-[100px] md:min-w-[150px] w-[30%] Button px-[12px]"
                style={{ border: `2px solid ${themeContext?.theme.color}` }}
                onClick={setGoal}
              >
                Continue Setup
              </button>
            </div>
          </>
        )} */}
      </div>

      {/** Content Part */}

      <div className="flex flex-col gap-y-[16px] md:justify-between">
        {/** First Section Start */}
        <div className="flex flex-col md:flex-row md:gap-x-[16px] gap-[8px] relative" style={{ backgroundColor: themeContext?.theme.foreground }}>

          <div
            className="flex flex-col w-full rounded-[8px] p-[24px] gap-y-6 "

          >
            <div className="flex flex-row justify-between title-f24-700">
              <div className="flex flex-row items-center gap-x-[10px]">
                <h3>Performance Overview</h3>
                <Tooltip title="Get a high-level view of overall campaign performance, including impressions, clicks, CTR, conversions, and CPA across all active campaigns." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </div>

            </div>
            {/* <p className="b4">Metrics across all active campaigns (Last 30 Days)</p> */}
            <div className="flex md:flex-row flex-col gap-4 justify-between">

              <select
                className="px-[12px] py-2 rounded-[50px] Button md:w-[60%] w-full"
                style={{
                  backgroundColor: themeContext?.theme.buttonBackground,
                  color: themeContext?.theme.color,
                  border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                }}
                onChange={(e) => {
                  setCampaignIndex(Number(e.target.value));
                  setChartData(campaignData[Number(e.target.value)]?.data);
                  setPerform(campaignData[Number(e.target.value)]?.data);
                }}
              >
                {
                  campaignData.length > 0 ? (
                    campaignData.map((item: any, index: number) => (
                      <option value={index} key={index}>{item.campaign_name}</option>
                    ))
                  ) : (<></>)
                }


              </select>
              <select
                className="px-[12px] py-2 rounded-[50px] Button md:w-[40%] w-full"
                style={{
                  backgroundColor: themeContext?.theme.buttonBackground,
                  color: themeContext?.theme.color,
                  border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                }}
                onChange={(e) => {
                  if (e.target.value === 'last7days') {
                    fetchMonth(7);
                  }
                  else if (e.target.value === 'last30days') {
                    fetchMonth(30);
                  } else if (e.target.value === 'currentYear') {
                    fetchYears();
                  }
                }}
              >
                <option value="last7days">Last 7 days</option>
                <option value="last30days">Last 30 days</option>
                <option value="currentYear">The current year</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="rounded-[2px] border-[#41ECCD] border-[2px] py-[5%] flex flex-col gap-[8px] items-center">
                <div className="flex flex-row gap-1 items-center"> <RemoveRedEyeOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">Impressions</p></div>
                <h5>{perform?.length > 0 ? formatNumber(getTotalValue('impressions')) : '0'}</h5>
              </div>
              <div className="rounded-[2px] border-[#41B9EC] border-[2px] py-[5%] flex flex-col gap-[8px] items-center">
                <div className="flex flex-row gap-1 items-center"> <LanguageOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">Clicks</p></div>
                <h5>{perform?.length > 0 ? formatNumber(getTotalValue('clicks')) : '0'}</h5>
              </div>
              <div className="rounded-[2px] border-[#6775F0] border-[2px] py-[5%] flex flex-col gap-[8px] items-center">
                <div className="flex flex-row gap-1 items-center"> <ShoppingCartOutlined style={{ fontSize: 16 }} /> <p className="b5">Conversions</p></div>
                <h5>{perform?.length > 0 ? formatNumber(getTotalValue('conversions')) : '0'}</h5>
              </div>
              <div className="rounded-[2px] border-[#ECBC41] border-[2px] py-[5%] flex flex-col gap-[8px] items-center">
                <div className="flex flex-row gap-1 items-center"> <RemoveRedEyeOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">CPA</p></div>
                <h5>{perform?.length > 0 ?  '$' + formatNumber(getTotalValue('CPA'))  : '$0'}</h5>
              </div>
            </div>

          </div>
          <div className="w-full">
            <div
              className="h-[350px]"
              style={{ transform: "translateY(-30px)" }}
            >
              {
                perform?.length > 0 ? (
                  <LineChartComponent data={testData} />
                ) : (<></>)

              }

            </div>
            <div className="flex justify-end Button pr-4">
              <button
                className="px-[15px] rounded-[50px] absolute bottom-[20px] w-fit"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                }}
                onClick={() => handleGoPage("analytics")}
              >
                See Analytics
              </button>
            </div>
          </div>

          {/* <div
            className="flex flex-col md:w-[55%] rounded-[8px] p-[24px] gap-y-[16px] relative"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex flex-row justify-between title-f24-700">
              <div className="flex flex-row items-center gap-x-[10px]">
                <h3>Strategic Insights</h3>
                <Tooltip title="AI-powered recommendations to improve your campaign performance, with insights into budget allocation, targeting, and optimization strategies." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </div>
              <button
                className="px-[12px] rounded-[50px] Button"
                style={{
                  backgroundColor: themeContext?.theme.buttonBackground,
                  color: themeContext?.theme.color,
                  border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                }}
              >
                Last 30days
              </button>
            </div>
            <h5 className="py-[8px]">
              Insights Summary
            </h5>
            <InfoAlert str="Add 3 broad match keywords to Campaign C to boost impressions by 15%." />
            <h5>Actionable Suggestions</h5>
            <StrategyTable />
            <div className="flex justify-end Button">
              <button
                className="px-[15px] rounded-[50px] absolute bottom-[20px] w-fit"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                }}
                onClick={() => {
                  handleGoPage("strategies");
                }}
              >
                Explore Strategies
              </button>
            </div>
          </div> */}
        </div>
        {/** First Part End */}

        {/** Second Part Start */}
        <div className="flex flex-col md:flex-row md:gap-x-[16px] gap-[16px] ">
          <div
            className="flex flex-col w-full rounded-[8px] p-[24px] gap-y-[16px] relative"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex flex-col md:flex-row justify-between title-f24-700 gap-4">
              <div className="flex flex-row items-center gap-x-[10px] ">
               
                  <h3>Top Performing Creative</h3>
                  <Tooltip title="Discover which creatives are driving the most engagement and conversions, along with suggestions for optimization." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                    <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                  </Tooltip>

                
              </div>
              <div className="flex flex-row gap-x-[8px]">
                <select
                  className="px-[12px] rounded-[50px] Button w-[10rem] py-2"
                  style={{
                    backgroundColor: themeContext?.theme.buttonBackground,
                    color: themeContext?.theme.color,
                    border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                  }}
                  onChange={(e) => {
                    if (e.target.value === 'last7days') {
                      fetchTopMonth(7);
                    }
                    else if (e.target.value === 'last30days') {
                      fetchTopMonth(30);
                    } else if (e.target.value === 'currentYear') {
                      fetchTopYears();
                    }
                  }}
                >
                  <option value="last7days">Last 7 days</option>
                  <option value="last30days">Last 30 days</option>
                  <option value="currentYear">The current year</option>
                </select>
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-[16px]">
              <div className="flex flex-col md:w-[50%] w-full gap-y-[8px]">
                <div className="flex flex-row gap-8 justify-between">

                <p className="b5">{topCampaign ? topCampaign.campaign_name : ''}</p>
                <button onClick={() => fetchCreativeData(topCampaign)}>
                  <CachedIcon/>
                </button>
                </div>
                <CampaignImage
                  canDisplay={topCreativeUrl != null}
                  imageUrl={topCreativeUrl}
                />
              </div>
              <div className="flex flex-col md:w-[60%] w-full gap-y-[8px]">
                <p className="b5">Performance Details and Recommendations</p>
                <div className="grid grid-cols-2 gap-2">
                  <div className="rounded-[2px] border-[#41ECCD] border-[2px] py-[6%] flex flex-col gap-[8px] items-center">
                    <div className="flex flex-row gap-1 items-center"> <RemoveRedEyeOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">Impressions</p></div>
                    <h5>{topCampaign ? formatNumber(getTopTotalValue('impressions')) : '0'}</h5>
                  </div>
                  <div className="rounded-[2px] border-[#41B9EC] border-[2px] py-[6%] flex flex-col gap-[8px] items-center">
                    <div className="flex flex-row gap-1 items-center"> <LanguageOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">Clicks</p></div>
                    <h5>{topCampaign ? formatNumber(getTopTotalValue('clicks')) : '0'}</h5>
                  </div>
                  <div className="rounded-[2px] border-[#6775F0] border-[2px] py-[6%] flex flex-col gap-[8px] items-center">
                    <div className="flex flex-row gap-1 items-center"> <ShoppingCartOutlined style={{ fontSize: 16 }} /> <p className="b5">Conversions</p></div>
                    <h5>{topCampaign ? formatNumber(getTopTotalValue('conversions')) : '0'}</h5>
                  </div>
                  <div className="rounded-[2px] border-[#ECBC41] border-[2px] py-[6%] flex flex-col gap-[8px] items-center">
                    <div className="flex flex-row gap-1 items-center"> <RemoveRedEyeOutlinedIcon style={{ fontSize: 16 }} /> <p className="b5">CPA</p></div>
                    <h5>${topCampaign ? formatNumber(getTopTotalValue('CPA')) + "$" : '0$'}</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <InfoAlert str="Add a call-to-action overlay to the video at the 15-second mark to increase conversions by 10%." /> */}
            <div className="mb-[16px]"></div>
            <div className="flex flex-row justify-end gap-x-[8px] absolute bottom-[10px] right-[15px] ">
              <button
                className="px-[12px] rounded-[50px] Button"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                }}
                onClick={() => {
                  handleGoPage("campaigns");
                }}
              >
                View All Campaigns
              </button>
              {/* <button
                className="px-[12px] rounded-[50px] Button"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                  border: `1px solid ${themeContext?.theme.buttonBackground}`,
                }}
              >
                Use Top Creative
              </button> */}
            </div>
          </div>
          {/* <div
            className="flex flex-col md:w-[40%] rounded-[8px] p-[24px] gap-y-[16px] relative"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex flex-row justify-between title-f24-700">
              <div className="flex flex-row items-center gap-x-[10px]">
                <h3>Settings & Alerts</h3>
                <Tooltip title="Monitor your account health, manage account settings, and stay updated with critical alerts about budget pacing, campaign status, and performance anomalies." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <h5>Account Settings</h5>
              <p className="b5">{localStorage.getItem('userName')} (ID: {localStorage.getItem('customerId')})      <span className="b5 ml-[25%]"> Active </span></p>
            </div>
            <div className="flex flex-col gap-3 mb-[3rem] md:mb-[2rem]">
              <h5>Alerts</h5>
              <InfoAlert str="Campaign is projected to exceed its daily budget by $100." />
              <InfoAlert str="Campaign B’s CTR has dropped by 15% over the past week." />
            </div>
            <div className="flex flex-row justify-end gap-x-[8px] absolute bottom-[10px] right-[15px] ">
              <button
                className="px-[12px] rounded-[50px] Button w-fit"
                style={{
                  backgroundColor: themeContext?.theme.buttonBackground,
                  color: themeContext?.theme.color,
                  border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                }}
                onClick={() => router.push('/settings')}
              >
                Manage Settings
              </button>
              <button
                className="px-[12px] rounded-[50px] Button"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                  border: `1px solid ${themeContext?.theme.buttonBackground}`,
                }}
              >
                Adopt Optimal Settings
              </button>
            </div>
          </div> */}
        </div>
        {/** Second Part End */}
      </div>
      {/** Content End */}
    </div>
  );
};

export const ProgressBar: React.FC<{ step: number }> = ({ step }) => {
  const totalSteps = 6;

  return (
    <div className="md:w-[120px] w-[20%] h-[8px] flex flex-row items-center justify-center gap-[8px]">
      {Array.from({ length: totalSteps }).map((_, index) => {
        return (
          <div
            key={index}
            className={`h-[8px] w-full ${index <= step ? "bg-[#6775F0]" : "bg-[#141414]"
              } ${index === 0 ? "rounded-l-lg" : ""} ${index === totalSteps - 1 ? "rounded-r-lg" : ""
              }`}
          />
        );
      })}
    </div>
  );
};

export const CampaignItem: React.FC<{
  icon: ReactElement;
  color: string;
  text: string;
  performance: string;
}> = ({ icon, color, text, performance }) => {
  return (
    <div
      className="flex flex-row justify-between items-center gap-x-[4px] pr-[4px] rounded-[2px] w-full"
      style={{ border: `1px solid ${color}` }}
    >
      <div className="flex items-center gap-x-[2px]">
        {icon}
        <label className="b5">{text}</label>
      </div>
      <label className="Button" style={{ textAlign: "end" }}>
        {performance}
      </label>
    </div>
  );
};
