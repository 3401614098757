import axios from "axios";
import Button from "@mui/material/Button";
import UserContext from "../../utils/userContext";
import { motion, Variants } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useCallback, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { GoogleIcon } from "../component/icons";
import { toast } from "react-hot-toast";
import { useRouter } from "../../routes/hooks/index";
import { 
    collection, 
    getDocs, 
    query,
    where, 
    setDoc, 
    QuerySnapshot,
    DocumentData 
} from "firebase/firestore";
import { firestore } from "../../components/Firebase/firebase";
import { useGoogleLogin } from "@react-oauth/google";
import { getUserDataByMail } from "../../utils/helper";
import { SERVER_URL } from "../../utils/constant";

const pageVariants: Variants = {
    initial: { x: "-60%", opacity: 0 },
    initial2: { opacity: 0 },
    animate: {
        x: "0%",
        opacity: 1,
        transition: { type: "tween", duration: 0.6, ease: "easeInOut" },
    },
    exit: {
        x: "-60%",
        opacity: 0,
        transition: { duration: 0.6, ease: "easeInOut" },
    },
};

const typingEffect = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.5,
            type: "tween",
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse" as const,
        },
    },
};


const buttonVariants = {
    initial: { scale: 1 },
    hover: {
        scale: 1.1,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
        transition: { duration: 0.3 },
    },
};

interface StepProps {
    setPages: (page: number) => void;
}

const Login: React.FC<StepProps> = ({ setPages }) => {
    const themeContext = useContext(ThemeContext);
    const location = useLocation();
    const router = useRouter();
    const { setMail, setFirstName, setLastName, setloading } = useContext(UserContext);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: MouseEvent) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, []);

    const redirectToDashboard = useCallback(() => {
        if (localStorage.getItem("access_token")) {
            router.push("/dashboard");
        }
    }, [router]);

    useEffect(() => {
        redirectToDashboard();
    }, [redirectToDashboard]);

    const handleSignInSuccess = useCallback(
        async (codeResponse: any) => {
            setloading(true);
            try {
                const { access_token, refresh_token } = await axios.post("https://oauth2.googleapis.com/token", {
                    code: codeResponse.code,
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CLIENT_SECRET,
                    redirect_uri: process.env.REACT_APP_HOST,
                    grant_type: "authorization_code",
                }).then(response => response.data);

                const userInfo = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
                    headers: { Authorization: `Bearer ${access_token}` }
                }).then(response => response.data);

                setMail(userInfo.email);
                setFirstName(userInfo.given_name);
                setLastName(userInfo.family_name);

                localStorage.setItem("access_token", refresh_token);
                localStorage.setItem("_token", access_token);

                const userQuery = query(collection(firestore, "users"), where("mail", "==", userInfo.email));
                const querySnapshot = await getDocs(userQuery);

                if (querySnapshot.empty) {
                    await handleNewUser();
                } else {
                    await handleExistingUser(userInfo.email, querySnapshot, refresh_token);
                }
            } catch (error) {
                toast.error(`Error retrieving info`);
                console.error(error);
                setloading(false);
            } finally {
                setloading(false);
            }
        },
        [router, setloading, setMail, setFirstName, setLastName, setPages]
    );

    const handleNewUser = async () => setPages(1);

    const handleExistingUser = async (
        userEmail: string,
        querySnapshot: QuerySnapshot<DocumentData, DocumentData>, 
        refresh_token: string
    ) => {
        const response = await axios.post(`${SERVER_URL}/customers`, {
          refresh_token: refresh_token,
         });
        localStorage.setItem('customerId', response.data);

        const data = await getUserDataByMail(userEmail);
        localStorage.setItem('email', userEmail);
        localStorage.setItem('userName', (data?.firstName || ''));
        localStorage.setItem('industry', data?.experience?.industry || '');
    
        querySnapshot.forEach(async (doc) => {
          await setDoc(doc.ref, {
              accessToken: refresh_token
          }, { merge: true });
        });
        redirectToDashboard();
    };

    const SignIn = useGoogleLogin({
        flow: "auth-code",
        onSuccess: handleSignInSuccess,
        onError: (errorResponse) => {
            setloading(false);
            toast.error(`Login Failed`);
            console.error(errorResponse);
        },
        scope: "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/analytics.readonly",
    });

    const goPrivacy = () => {
        router.push('/privacy-policy');
    }
    return (
        <motion.section
            variants={pageVariants}
            initial={location.hasOwnProperty("state.name") ? "initial" : "initial2"}
            animate="animate"
            exit="exit"
        >
            <div className="relative flex flex-col items-center justify-center w-full max-w-md mx-auto px-4 py-8">

                <div className="pt-[40px]">
                    <motion.span
                        className="font-h2-700 font-grey"
                        variants={typingEffect}
                        initial="hidden"
                        animate="visible"
                    >
                        Get Started
                    </motion.span>
                </div>
                <div className="pt-[24px] pb-[24px]"></div>
                <div className="pb-[16px] B5">
                    Welcome to Torque AI! Let's set up your account to unlock powerful marketing insights.
                </div>


                <div className="flex justify-center w-full cursor-pointer md:mt-[32px] mt-[8px]">
                        <Button
                            variant="outlined"
                            startIcon={<GoogleIcon />}
                            sx={{
                                borderRadius: "50px",
                                border: `2px solid ${themeContext?.theme.activeButtonBackground}`,
                                textTransform: "none",
                                padding: "12px 30px",
                                width: "100%",
                                maxWidth: "350px",
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: "#000",
                                fontWeight: "bold",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                transition: "all 0.3s ease-in-out",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                '&:hover': {
                                    backgroundColor: themeContext?.theme.hoverBackground,
                                    borderColor: themeContext?.theme.hoverColor,
                                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
                                    color: "#FFFFFF"
                                },
                            }}
                            onClick={SignIn}
                        >
                            Continue with Google
                        </Button>
                </div>
                <div className="pt-[32px] pb-[40px]"></div>
                <button
                    onClick={() => goPrivacy()}>
                    Privacy Policy
                </button>
                <div className="pt-[8px] pb-[8px]"></div>
            </div>
        </motion.section>
    );
};

export default Login;
